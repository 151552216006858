.logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        max-width: 100%;
        min-width: 90%;
        display: inline-block;
    }
}