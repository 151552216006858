.projects-container-btn {
    display: inline-block;
    position: absolute;
    height: 50rem;
    width: 5rem;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    font-size: 6rem;
    color: rgba(var(--color-secondary-dark-rgb), .5);
    background-color: rgba($color: var(--color-primary-light-rgb), $alpha: .1);

    // @include respond(tab-land) {
    //     height: 76.5rem;
    //     top: 57%;
        
    // }

    &:hover {
        cursor: pointer;
        background-color: rgba($color: var(--color-primary-light-rgb), $alpha: .3);
        color: rgba(var(--color-secondary-light-rgb), .7);
    }
}



#btn-left {
    left:0;
}

#btn-right {
    right: 0;
}