.header-primary {
    font-weight: 800;
    font-size: 8.5rem;
    line-height: 11rem;
    text-transform: uppercase;
    display: inline-block;
    background: linear-gradient(90deg, rgb(224, 236, 226) 0%, rgb(222, 227, 223) 100%);
    -webkit-background-clip: text;
    color: transparent;
    margin: 2rem 0;

    @include respond(tab-land) {
        font-size: 6.5rem;
        line-height: 9rem;
    }
    @include respond(phone) {
        font-size: 5rem;
        line-height: 7rem;
    }

}
.header-secondary {
    font-weight: 800;
    font-size: 10rem;
    text-transform: uppercase;
    display: inline-block;
    background: linear-gradient(90deg, rgba(var(--color-primary-dark-rgb), 0.9) 0%, rgba(var(--color-primary-dark-rgb), 0.6) 100%);
    -webkit-background-clip: text;
    color: transparent;
    margin: 2rem 0;
    @include respond(tab-port) {
        font-size: 9rem;
    }

    @include respond(phone) {
        font-size: 6rem;
    }
}

.header-tertiary {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    text-transform: uppercase;

   
}