.projects-container {
    // display: flex;
    overflow: hidden;
    min-width: 90vw;
    transform: translateX(0);
    transition: transform .8s;
    position: relative;
    padding: 5rem 0;

    @include respond(tab-land) {
        min-height: 95vh;
    }
    
    @include respond(tab-port) {
        min-width: 50vw;
        max-width: 90vw;    
    }

    @include respond(phone) {
        max-width: 75vw;
    }


    &-cards {
        display: flex;
        transition: transform .8s;
        padding: 6rem 2rem;
        // background-color: var(--color-grey-lightest);
        background-clip: padding-box;
    }
    
}

