.project-card {
  position: relative;
  width: 35rem;
  max-width: 35rem;
  min-width: 30rem;
  margin: 0 2vw;
  height: 50rem;
  perspective: 150rem;

  @include respond(tab-land) {
    height: 45rem;
  }

  &-side {
    height: 100%;
    width: 100%;
    transition: transform 0.8s;
    border-radius: 5px;
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.3);

    &-front {
      // border: 2px solid var(--color-primary-light);
      backface-visibility: hidden;
      background-color: var(--color-primary-dark);
      padding: 2rem 2.5rem;
      text-align: center;
      & p {
        font-size: 1.2rem;
        text-transform: uppercase;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        color: var(--color-secondary-dark);

        @include respond(tab-land) {
          display: none;
        }

      }
    }

    &-back {
      // border: 2px solid var(--color-primary-light);
      backface-visibility: hidden;
      background-color: var(--color-primary-light);
      transform: rotateY(180deg);
      position: absolute;
      top: 0;
      left: 0;
      padding: 2rem 2.5rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      color: var(--color-secondary-light);

      @include respond(tab-land) {
        padding-top: 5rem;
        transform: rotateY(0deg);
        top: 70%;
        font-weight: 500;
        font-size: 1.3rem;
        clip-path: polygon(0 4vh, 100% 0, 100% 100%, 0 100%);
      }

    }

  }

  &-img-container {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    
  }

  &-image {
    max-width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    
  }
  &-title {
    padding: 0 2.5rem;
    font-size: 3rem;
    color: var(--color-secondary-dark);
  }


  &-details {
    margin-bottom: 6rem;
    &-title {
      margin-bottom: 2rem;
    }
  }

  &:hover &-side-front {
    transform: rotateY(180deg) scale(1.1);

    @include respond(tab-land) {
      transform: rotateY(0) scale(1);
    }
  }
  &:hover &-side-back {
    transform: rotateY(360deg) scale(1.1);

    @include respond(tab-land) {
      transform: rotateY(0) scale(1);
    }
  }

}
