.footer {
    min-height: 60vh;
    width: 100%;
    // background-image: linear-gradient(to right bottom, var(--color-primary-light) , var(--color-primary-dark));
    background-image: linear-gradient(to right bottom, rgba(var(--color-primary-light-rgb)), var(--color-primary-dark) );

    clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
    margin-top: -15vh;
    padding: 15vh 3rem 3rem 3rem;
    position: relative;
    @include respond(phone) {
        clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
    }

    & .header-secondary {
        color: var(--color-secondary-dark);

        @include respond(phone) {
            font-size: 6rem;
        }
    }

    &-content {
        
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            // padding: 1rem;
            
        
    }
    &-text {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        align-self:flex-end;
        padding: 3rem 0;
        font-size: 2rem;
        
        
        @include respond(phone) {
            flex-basis: 100%;
        }

        &__copyright {
            font-size: 1.4rem;
            position: absolute;
            bottom: 5;
            right: 5;
            z-index: 10000;
        }
    }

    &-links {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        align-items: center;
        // padding: 2rem;
        list-style: none;
        @include respond(phone) {
            flex-direction: column;
        }

        & a {
            text-decoration: none;
            color: var(--color-secondary-dark);
        }

    }
    &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        // width: 3rem;
        // text-decoration: none;
        // text-transform: uppercase;
        // color: var(--color-white);
        font-size: 3rem;
        // font-weight: 300;
        // padding: 1rem 2rem;
        background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-white) 50% );
        background-size: 290%;
        transition: all .5s;
        
        &:hover,
        &:active {
            background-position: 100%;
            color: var(--color-primary-dark);
            transform: scale(1.1);
        }

        &:hover .footer-svg
        {
            fill: var(--color-primary-dark);
        }

        &__svg
        {
            height: 2.5rem;
            width: 2.5rem;
            margin: 0 1rem;
            // background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-white) 50% );
            // background-size: 290%;
            // transition: all .4s;

            // &:hover {
            //     background-position: 100%;
            //     color: var(--color-primary-dark);
            //     // transform: translateX(1rem);
            // }
        }
    }

    &-svg
    {
        height: 2.5rem;
        width: 2.5rem;
        fill: var(--color-secondary-dark);
               
    }

    &-image {
        flex-basis: 30%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__container {
            width: 22rem;
            height: 22rem;
            border-radius: 50%;
            overflow: hidden;
            background-image: url(/pics/Andras_contact1.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            // background-position: center;
            background-position: -7rem 0;
            margin: 3rem 0;
        }
        &__text {
            font-size: 1.5rem;
            text-align: center;
        }
    }
}

