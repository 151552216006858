.btn {
    &:link,
    &:visited {
        position: relative;
        text-decoration: none;   
        border-radius: 500px;
        text-transform: uppercase;
        display: inline-block;
        line-height: 200%;
        box-shadow: 0 .2rem 2rem rgba(0,0,0, .3);
        transition: all .3s;
        backface-visibility: hidden;
    }

    &-large{
        width: 33rem;
        height: 7rem;
        font-size: 2rem;
        padding: 1.5rem 3rem;

        @include respond(tab-port) {
            width: 31rem;
            height: 7rem;
            font-size: 2rem;
            padding: 1.5rem 2rem;
        }
        @include respond(phone) {
            width: 26rem;
            height: 6rem;
            font-size: 1.5rem;
            padding: 1.5rem 2rem;
        }
    }

    &-white {
        background-color: var(--color-white);
        color: var(--color-primary-dark);
    }

    &-purple {
        background-color: var(--color-secondary-dark);
        color: var(--color-white);
    }

    &:hover {
        transform: translateY(-.3rem);
        box-shadow: 0 2rem 4rem rgba(0,0,0, .5);
    }

    &:active {
        transform: translateY(-.1rem);
        box-shadow: 0 1.5rem 3rem rgba(0,0,0, .5);
    }

    &::after {
        content: "";
        height: 100%;
        width: 100%;
        background-color: inherit;
        display: inline-block;
        border-radius: 500px;
        position: absolute;
        top:0;
        left: 0;
        z-index: -1;
        transition: all .4s;
    }

    &:hover::after {
        transform: scaleX(1.3) scaleY(1.5);
        opacity: 0;
    }
}