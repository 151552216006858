@keyframes header-img {
    0% {
        opacity: 0;
        transform: translateX(-5rem);
    }
    
    80% {
        transform: translate(1rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes header-text {
    0% {
        opacity: 0;
        transform: translateX(5rem);
    }

    80% {
        transform: translate(-.5rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes header-btn {
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }

 
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}