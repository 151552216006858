.header {
    height: 95vh;
    // background-color: rgba(159, 123, 31, 0.6);
    // background-image: linear-gradient(to right bottom, rgba(var(--color-primary-light-rgb), .5), rgba(var(--color-primary-dark-rgb), .5) );
    // background-image: linear-gradient(to right bottom, var(--color-primary-light), var(--color-primary-dark) );
    background-image: linear-gradient(to right bottom, rgba(var(--color-primary-light-rgb)), var(--color-primary-dark) );
    clip-path: polygon(0 0, 100% 0%, 100% 75vh, 0% 100%);
    max-width: 100%;
    position: relative;

    @include respond(tab-port) {
        clip-path: polygon(0 0, 100% 0%, 100% 85vh, 0% 100%);
    }
    
    @include respond(phone) {
        clip-path: polygon(0 0, 100% 0%, 100% 90vh, 0% 100%);
    }

    & .flex-container {
        position: relative;
        display:flex;
        justify-content: space-evenly;
        align-items: center;

        @include respond(tab-port) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0;
        }

        @include respond(tab-land) {
            // margin-top: 6rem;
            padding-top: 5rem;
        }

        & .flex-item {
            margin: 1rem 0;
            display: inline-block;

            &_img {
                width: 300rem;
                padding: 2rem .5rem .5rem .5rem;
                animation: header-img 1s;
                flex-shrink: 1; 

                @include respond(tab-port) {
                    position: relative;
                    max-width: 80%;
                    max-height: 45vh;
                    padding: 0;
                    text-align: center;
                  }
            }

            &_text {
                // align-self: center;
                font-weight: 600;
                color: var(--color-white);
                text-transform: uppercase;
                letter-spacing: 0.15em;
                padding: 3rem ;
                animation: header-text .8s;
                flex-shrink: 1.5;

                & p {
                    margin-bottom: 3rem;
                    font-size: 3rem;

                    @include respond(tab-land) {
                        font-size: 2.5rem
                    }
                    
                    @include respond(phone) {
                        font-size: 2rem
                    }
                }

                // & a {
                //     position: absolute;
                //     right: 10rem;
                //     bottom: 5rem;
                // }
            }
        }
    }

    &-img{
        max-width: 90%;
        max-height: 80vh;

        @include respond(tab-port) {
            max-height: 45vh;
            display: inline-block;
        }
    }
    & .btn {
        animation: header-btn .5s ease-in .6s backwards;
        
    }
}