.section-about {
    background-color: var(--color-grey-light);
    margin-top: -20vh;
    padding: 20rem 3rem 20rem 3rem ;

    @include respond(tab-port) {
        // flex-direction: column;
        padding: 25rem 3rem 0rem 3rem ;
    }

    & .flex-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10rem;
        // padding: 2rem;

        
        @include respond(tab-port) {
            flex-direction: column;

            &:nth-of-type(2) {
                flex-direction: column-reverse;
            }
        }
    }

    & .flex-item {
        align-self: center;
        max-width: 45%;


        @include respond(tab-port) {
            max-width: none;
            align-self: stretch;
        }
    }

    &__img {
        max-width: 90%;
        
    }

    &__text {
        font-weight: 400;
        font-size: 3rem;
        line-height: 4rem;
        letter-spacing: 0.17em;
        max-width: 40vw;
        color: var(--color-primary-dark);

        @include respond(tab-port) {
            margin-top: 5rem;
            display: inline-block;
            min-width: 90%;
            text-align: center;
        }

        @include respond(tab-land) {
            font-size: 2.5rem;
            line-height: 3.5rem;
        }
    }

    &__cardbg {
        position: relative;
        z-index: 0;
        margin: 20rem 0;
        width: 100%;
        height: 80vh;
        background-image: url(../pics/about-card-bg2.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transform: skewY(-5deg);

        @include respond(tab-port) {
            min-height:200rem;
        }

        @include respond(phone) {
            margin-top: 10rem;
            min-height: 230rem;
        }

        &::before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            background-color: rgba( var(--color-primary-dark-rgb), .7);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        & .card-container {
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) skewY(5deg);
            width: 80%;
            height: 50rem;
            // background-color: aqua;
            display: flex;
            align-items: stretch;
            justify-content: space-between;


            @include respond(tab-land) {
                justify-content: space-around;
                height: 55rem;
            }

            @include respond(tab-port) {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                top: 20%;
                left: 50%;
                transform: translate(-50%, -50%) skewY(5deg);
                // min-height: 100rem;
            }

            @include respond(phone) {
                top:15%
            }


        }
    }

    &__card {
        max-width: 25rem;
        height: 50rem;
        max-height: 80%;
        padding: 2.5rem;
        border: 3px solid rgba($color: #fff, $alpha: .5);
        border-radius: 5px;
        color: #fff;
        text-align: center;
        margin: 5rem 0;
        transition: transform .3s;
        backface-visibility: hidden;

        @include respond(tab-land) {
            max-width: 20rem;
            max-height: 90%;
        }
        
        @include respond(tab-port) {
            max-width: 70%;
        }

        @include respond(phone) {
            max-width: 90%;
        }

        & span {
            font-size: 8rem;
        }

        & p {
            font-weight: 600;
            font-size: 1.3rem;
            letter-spacing: .1rem;
            line-height: 140.19%;
            text-transform: uppercase;
            margin: 2rem 0;

            @include respond(tab-port) {
                font-size: 2rem;
            }
            
            @include respond(phone) {
                font-size: 1.3rem;
            }
        }

        &:hover {
            transform: scale(1.2);
        }
    }
}

.section-video {
    // position: relative;
    // width: 100%;
    // height: 95vh;
    // overflow: hidden;
    position: relative;
    height: 100vh;
    background-color: var(--color-grey-light);
    margin-top: -25vh;

    @include respond(tab-port) {
        display: none;
    }
}

.section-projects {
    // margin-top: 10rem;
    background-color: var(--color-grey-light);
    // overflow: hidden;
    padding: 20rem 3rem 20rem 3rem ;

    @include respond(tab-port) {
       padding: 3rem 3rem 20rem 3rem;
       margin-top: -20rem;
    }

    &-text {
        color: var(--color-primary-dark);
        font-size: 2.6rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: .4rem;
    }

    &-comment
    {
        color: var(--color-secondary-dark);
    }
}