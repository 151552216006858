@import "https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap";
@keyframes header-img {
  0% {
    opacity: 0;
    transform: translateX(-5rem);
  }

  80% {
    transform: translate(1rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes header-text {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }

  80% {
    transform: translate(-.5rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes header-btn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

:root {
  --color-primary-dark: #cbb886;
  --color-primary-dark-rgb: 203, 184, 134;
  --color-primary-light: #c6b588;
  --color-primary-light-rgb: 198, 181, 136;
  --color-secondary-dark: #4f7596;
  --color-secondary-dark-rgb: 204, 213, 174;
  --color-secondary-light: #688fb0;
  --color-secondary-light-rgb: 233, 237, 201;
  --color-grey-lightest: #ddd;
  --color-grey-light: #eee;
  --color-grey-med: #aaa;
  --color-grey-dark: #777;
  --color-white: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  padding: 3rem;
  font-family: Roboto Flex, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
}

.header-primary {
  text-transform: uppercase;
  background: linear-gradient(90deg, #e0ece2 0%, #dee3df 100%);
  color: #0000;
  -webkit-background-clip: text;
  margin: 2rem 0;
  font-size: 8.5rem;
  font-weight: 800;
  line-height: 11rem;
  display: inline-block;
}

@media (max-width: 75em) {
  .header-primary {
    font-size: 6.5rem;
    line-height: 9rem;
  }
}

@media (max-width: 37.5em) {
  .header-primary {
    font-size: 5rem;
    line-height: 7rem;
  }
}

.header-secondary {
  text-transform: uppercase;
  background: linear-gradient(90deg, rgba(var(--color-primary-dark-rgb), .9) 0%, rgba(var(--color-primary-dark-rgb), .6) 100%);
  color: #0000;
  -webkit-background-clip: text;
  margin: 2rem 0;
  font-size: 10rem;
  font-weight: 800;
  display: inline-block;
}

@media (max-width: 56.25em) {
  .header-secondary {
    font-size: 9rem;
  }
}

@media (max-width: 37.5em) {
  .header-secondary {
    font-size: 6rem;
  }
}

.header-tertiary {
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}

.btn:link, .btn:visited {
  text-transform: uppercase;
  backface-visibility: hidden;
  border-radius: 500px;
  line-height: 200%;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  box-shadow: 0 .2rem 2rem #0000004d;
}

.btn-large {
  width: 33rem;
  height: 7rem;
  padding: 1.5rem 3rem;
  font-size: 2rem;
}

@media (max-width: 56.25em) {
  .btn-large {
    width: 31rem;
    height: 7rem;
    padding: 1.5rem 2rem;
    font-size: 2rem;
  }
}

@media (max-width: 37.5em) {
  .btn-large {
    width: 26rem;
    height: 6rem;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
  }
}

.btn-white {
  background-color: var(--color-white);
  color: var(--color-primary-dark);
}

.btn-purple {
  background-color: var(--color-secondary-dark);
  color: var(--color-white);
}

.btn:hover {
  transform: translateY(-.3rem);
  box-shadow: 0 2rem 4rem #00000080;
}

.btn:active {
  transform: translateY(-.1rem);
  box-shadow: 0 1.5rem 3rem #00000080;
}

.btn:after {
  content: "";
  background-color: inherit;
  z-index: -1;
  border-radius: 500px;
  width: 100%;
  height: 100%;
  transition: all .4s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn:hover:after {
  opacity: 0;
  transform: scaleX(1.3)scaleY(1.5);
}

.bg-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.bg-video__content {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.75);
}

.video-title {
  color: var(--color-primary-dark);
  opacity: 0;
  font-size: 5.5rem;
  transition: transform .8s ease-in-out, opacity .8s ease-in-out;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

@media (max-width: 75em) {
  .video-title {
    font-size: 4.5rem;
    top: 50%;
  }
}

.logo {
  justify-content: center;
  align-items: center;
  width: 8rem;
  display: flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.logo img {
  min-width: 90%;
  max-width: 100%;
  display: inline-block;
}

.projects-container-btn {
  color: rgba(var(--color-secondary-dark-rgb), .5);
  background-color: rgba(var(--color-primary-light-rgb), .1);
  border: none;
  width: 5rem;
  height: 50rem;
  font-size: 6rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.projects-container-btn:hover {
  cursor: pointer;
  background-color: rgba(var(--color-primary-light-rgb), .3);
  color: rgba(var(--color-secondary-light-rgb), .7);
}

#btn-left {
  left: 0;
}

#btn-right {
  right: 0;
}

.project-card {
  perspective: 150rem;
  width: 35rem;
  min-width: 30rem;
  max-width: 35rem;
  height: 50rem;
  margin: 0 2vw;
  position: relative;
}

@media (max-width: 75em) {
  .project-card {
    height: 45rem;
  }
}

.project-card-side {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  transition: transform .8s;
  box-shadow: 0 2rem 4rem #0000004d;
}

.project-card-side-front {
  backface-visibility: hidden;
  background-color: var(--color-primary-dark);
  text-align: center;
  padding: 2rem 2.5rem;
}

.project-card-side-front p {
  text-transform: uppercase;
  color: var(--color-secondary-dark);
  width: 100%;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 75em) {
  .project-card-side-front p {
    display: none;
  }
}

.project-card-side-back {
  backface-visibility: hidden;
  background-color: var(--color-primary-light);
  text-transform: uppercase;
  color: var(--color-secondary-light);
  padding: 2rem 2.5rem;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

@media (max-width: 75em) {
  .project-card-side-back {
    -webkit-clip-path: polygon(0 4vh, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 4vh, 100% 0, 100% 100%, 0 100%);
    padding-top: 5rem;
    font-size: 1.3rem;
    font-weight: 500;
    top: 70%;
    transform: rotateY(0);
  }
}

.project-card-img-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.project-card-image {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  max-width: 100%;
}

.project-card-title {
  color: var(--color-secondary-dark);
  padding: 0 2.5rem;
  font-size: 3rem;
}

.project-card-details {
  margin-bottom: 6rem;
}

.project-card-details-title {
  margin-bottom: 2rem;
}

.project-card:hover .project-card-side-front {
  transform: rotateY(180deg)scale(1.1);
}

@media (max-width: 75em) {
  .project-card:hover .project-card-side-front {
    transform: rotateY(0)scale(1);
  }
}

.project-card:hover .project-card-side-back {
  transform: rotateY(360deg)scale(1.1);
}

@media (max-width: 75em) {
  .project-card:hover .project-card-side-back {
    transform: rotateY(0)scale(1);
  }
}

.header {
  background-image: linear-gradient(to right bottom, rgba(var(--color-primary-light-rgb)), var(--color-primary-dark));
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 75vh, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 75vh, 0% 100%);
  max-width: 100%;
  height: 95vh;
  position: relative;
}

@media (max-width: 56.25em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 85vh, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 85vh, 0% 100%);
  }
}

@media (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 90vh, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 90vh, 0% 100%);
  }
}

.header .flex-container {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-width: 56.25em) {
  .header .flex-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

@media (max-width: 75em) {
  .header .flex-container {
    padding-top: 5rem;
  }
}

.header .flex-container .flex-item {
  margin: 1rem 0;
  display: inline-block;
}

.header .flex-container .flex-item_img {
  flex-shrink: 1;
  width: 300rem;
  padding: 2rem .5rem .5rem;
  animation: 1s header-img;
}

@media (max-width: 56.25em) {
  .header .flex-container .flex-item_img {
    text-align: center;
    max-width: 80%;
    max-height: 45vh;
    padding: 0;
    position: relative;
  }
}

.header .flex-container .flex-item_text {
  color: var(--color-white);
  text-transform: uppercase;
  letter-spacing: .15em;
  flex-shrink: 1.5;
  padding: 3rem;
  font-weight: 600;
  animation: .8s header-text;
}

.header .flex-container .flex-item_text p {
  margin-bottom: 3rem;
  font-size: 3rem;
}

@media (max-width: 75em) {
  .header .flex-container .flex-item_text p {
    font-size: 2.5rem;
  }
}

@media (max-width: 37.5em) {
  .header .flex-container .flex-item_text p {
    font-size: 2rem;
  }
}

.header-img {
  max-width: 90%;
  max-height: 80vh;
}

@media (max-width: 56.25em) {
  .header-img {
    max-height: 45vh;
    display: inline-block;
  }
}

.header .btn {
  animation: .5s ease-in .6s backwards header-btn;
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  background-color: var(--color-white);
  z-index: 2000;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  position: fixed;
  top: 6rem;
  right: 6rem;
  box-shadow: 0 1rem 3rem #0000001a;
}

.navigation__background {
  z-index: 1000;
  background-image: radial-gradient(#cbb886, #cbb886);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  transition: all .8s cubic-bezier(0, .55, .45, 1);
  position: fixed;
  top: 6.5rem;
  right: 6.5rem;
}

.navigation__nav {
  z-index: 1500;
  opacity: 0;
  width: 0;
  height: 100vh;
  transition: all .6s cubic-bezier(.68, -.6, .32, 1.6);
  position: fixed;
  top: 0;
  right: 0;
}

.navigation__list {
  text-align: center;
  width: 100%;
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation__item {
  margin: 1rem;
}

.navigation__link:link, .navigation__link:visited {
  text-transform: uppercase;
  color: var(--color-white);
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-white) 50%);
  background-size: 230%;
  padding: 1rem 2rem;
  font-size: 3rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .4s;
  display: inline-block;
}

.navigation__link:hover, .navigation__link:active {
  color: var(--color-primary-dark);
  background-position: 100%;
  transform: translateX(1rem);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(70);
}

.navigation__checkbox:checked ~ .navigation__nav {
  opacity: 1;
  width: 100%;
}

.navigation__icon {
  margin-top: 3.3rem;
  position: relative;
}

.navigation__icon, .navigation__icon:before, .navigation__icon:after {
  background-color: var(--color-secondary-dark);
  transition: transform .2;
  width: 3rem;
  height: 2px;
  display: inline-block;
}

.navigation__icon:before, .navigation__icon:after {
  content: "";
  transition: transform .3s;
  position: absolute;
  left: 0;
}

.navigation__icon:before {
  top: -.8rem;
}

.navigation__icon:after {
  top: .8rem;
}

.navigation__button:hover .navigation__icon:before {
  transform: translateY(-.2rem);
}

.navigation__button:hover .navigation__icon:after {
  transform: translateY(.2rem);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: #0000;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

.footer {
  background-image: linear-gradient(to right bottom, rgba(var(--color-primary-light-rgb)), var(--color-primary-dark));
  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  width: 100%;
  min-height: 60vh;
  margin-top: -15vh;
  padding: 15vh 3rem 3rem;
  position: relative;
}

@media (max-width: 37.5em) {
  .footer {
    -webkit-clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0 100%);
  }
}

.footer .header-secondary {
  color: var(--color-secondary-dark);
}

@media (max-width: 37.5em) {
  .footer .header-secondary {
    font-size: 6rem;
  }
}

.footer-content {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-text {
  flex-direction: column;
  flex-basis: 30%;
  align-self: flex-end;
  padding: 3rem 0;
  font-size: 2rem;
  display: flex;
}

@media (max-width: 37.5em) {
  .footer-text {
    flex-basis: 100%;
  }
}

.footer-text__copyright {
  z-index: 10000;
  font-size: 1.4rem;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.footer-links {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  display: flex;
}

@media (max-width: 37.5em) {
  .footer-links {
    flex-direction: column;
  }
}

.footer-links a {
  color: var(--color-secondary-dark);
  text-decoration: none;
}

.footer-link {
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-white) 50%);
  background-size: 290%;
  justify-content: center;
  align-items: center;
  height: 3rem;
  font-size: 3rem;
  transition: all .5s;
  display: flex;
}

.footer-link:hover, .footer-link:active {
  color: var(--color-primary-dark);
  background-position: 100%;
  transform: scale(1.1);
}

.footer-link:hover .footer-svg {
  fill: var(--color-primary-dark);
}

.footer-link__svg {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 1rem;
}

.footer-svg {
  fill: var(--color-secondary-dark);
  width: 2.5rem;
  height: 2.5rem;
}

.footer-image {
  flex-direction: column;
  flex-basis: 30%;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.footer-image__container {
  background-image: url("Andras_contact1.82ebe393.jpg");
  background-position: -7rem 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 22rem;
  height: 22rem;
  margin: 3rem 0;
  overflow: hidden;
}

.footer-image__text {
  text-align: center;
  font-size: 1.5rem;
}

.projects-container {
  min-width: 90vw;
  padding: 5rem 0;
  transition: transform .8s;
  position: relative;
  overflow: hidden;
  transform: translateX(0);
}

@media (max-width: 75em) {
  .projects-container {
    min-height: 95vh;
  }
}

@media (max-width: 56.25em) {
  .projects-container {
    min-width: 50vw;
    max-width: 90vw;
  }
}

@media (max-width: 37.5em) {
  .projects-container {
    max-width: 75vw;
  }
}

.projects-container-cards {
  background-clip: padding-box;
  padding: 6rem 2rem;
  transition: transform .8s;
  display: flex;
}

.section-about {
  background-color: var(--color-grey-light);
  margin-top: -20vh;
  padding: 20rem 3rem;
}

@media (max-width: 56.25em) {
  .section-about {
    padding: 25rem 3rem 0;
  }
}

.section-about .flex-container {
  justify-content: space-between;
  margin-bottom: 10rem;
  display: flex;
}

@media (max-width: 56.25em) {
  .section-about .flex-container {
    flex-direction: column;
  }

  .section-about .flex-container:nth-of-type(2) {
    flex-direction: column-reverse;
  }
}

.section-about .flex-item {
  align-self: center;
  max-width: 45%;
}

@media (max-width: 56.25em) {
  .section-about .flex-item {
    align-self: stretch;
    max-width: none;
  }
}

.section-about__img {
  max-width: 90%;
}

.section-about__text {
  letter-spacing: .17em;
  color: var(--color-primary-dark);
  max-width: 40vw;
  font-size: 3rem;
  font-weight: 400;
  line-height: 4rem;
}

@media (max-width: 56.25em) {
  .section-about__text {
    text-align: center;
    min-width: 90%;
    margin-top: 5rem;
    display: inline-block;
  }
}

@media (max-width: 75em) {
  .section-about__text {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

.section-about__cardbg {
  z-index: 0;
  background-image: url("about-card-bg2.5119cfd5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
  margin: 20rem 0;
  position: relative;
  transform: skewY(-5deg);
}

@media (max-width: 56.25em) {
  .section-about__cardbg {
    min-height: 200rem;
  }
}

@media (max-width: 37.5em) {
  .section-about__cardbg {
    min-height: 230rem;
    margin-top: 10rem;
  }
}

.section-about__cardbg:before {
  content: "";
  background-color: rgba(var(--color-primary-dark-rgb), .7);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.section-about__cardbg .card-container {
  z-index: 2;
  justify-content: space-between;
  align-items: stretch;
  width: 80%;
  height: 50rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)skewY(5deg);
}

@media (max-width: 75em) {
  .section-about__cardbg .card-container {
    justify-content: space-around;
    height: 55rem;
  }
}

@media (max-width: 56.25em) {
  .section-about__cardbg .card-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%)skewY(5deg);
  }
}

@media (max-width: 37.5em) {
  .section-about__cardbg .card-container {
    top: 15%;
  }
}

.section-about__card {
  color: #fff;
  text-align: center;
  backface-visibility: hidden;
  border: 3px solid #ffffff80;
  border-radius: 5px;
  max-width: 25rem;
  height: 50rem;
  max-height: 80%;
  margin: 5rem 0;
  padding: 2.5rem;
  transition: transform .3s;
}

@media (max-width: 75em) {
  .section-about__card {
    max-width: 20rem;
    max-height: 90%;
  }
}

@media (max-width: 56.25em) {
  .section-about__card {
    max-width: 70%;
  }
}

@media (max-width: 37.5em) {
  .section-about__card {
    max-width: 90%;
  }
}

.section-about__card span {
  font-size: 8rem;
}

.section-about__card p {
  letter-spacing: .1rem;
  text-transform: uppercase;
  margin: 2rem 0;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 140.19%;
}

@media (max-width: 56.25em) {
  .section-about__card p {
    font-size: 2rem;
  }
}

@media (max-width: 37.5em) {
  .section-about__card p {
    font-size: 1.3rem;
  }
}

.section-about__card:hover {
  transform: scale(1.2);
}

.section-video {
  background-color: var(--color-grey-light);
  height: 100vh;
  margin-top: -25vh;
  position: relative;
}

@media (max-width: 56.25em) {
  .section-video {
    display: none;
  }
}

.section-projects {
  background-color: var(--color-grey-light);
  padding: 20rem 3rem;
}

@media (max-width: 56.25em) {
  .section-projects {
    margin-top: -20rem;
    padding: 3rem 3rem 20rem;
  }
}

.section-projects-text {
  color: var(--color-primary-dark);
  text-transform: uppercase;
  letter-spacing: .4rem;
  font-size: 2.6rem;
  font-weight: 500;
}

.section-projects-comment {
  color: var(--color-secondary-dark);
}

/*# sourceMappingURL=index.9b7e570e.css.map */
