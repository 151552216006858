
@mixin centerElament {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}




//MEDIA QUERY MANAGER

/*
0 - 600px:          Phone
600px - 900px:      Tablet portrait
900px - 1200px:     Tablet landscape
1200px - 1800px:    Desktop, ez a normál styling
1800px -            Big Desktop
*/


/* $breakpoin változatok 
- phone
- tab-port
- tab-land
- big-desktop
1em = 16px
*/

@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media (max-width: 37.5em) {@content}; //600px
    }

    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) {@content}; //900px
    }

    @if $breakpoint == tab-land {
        @media (max-width: 75em) {@content}; //1200px
    }

    @if $breakpoint == big-desktop {
        @media (min-width: 112.5em) {@content}; //1800px
    }

}
