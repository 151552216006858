.navigation {
    
    &__checkbox {
        display: none;

    }
    &__button {
        position: fixed;
        top: 6rem;
        right: 6rem;
        height: 7rem;
        width: 7rem;
        border-radius: 50%;
        background-color: var(--color-white);
        z-index: 2000;
        box-shadow: 0 1rem 3rem rgba($color: #000000, $alpha: 0.1);
        text-align: center;
        cursor: pointer;
    }

    &__background {
        position: fixed;
        top: 6.5rem;
        right: 6.5rem;
        height: 6rem;
        width: 6rem;
        border-radius: 50%;
        // background-image: radial-gradient(var(--color-primary-light), var(--color-primary-dark) );
        background-image: radial-gradient(#CBB886, #CBB886);
        z-index: 1000;
        transition: all .8s cubic-bezier(0, 0.55, 0.45, 1);

        // transform: scale(65);
    }

    &__nav {
        height: 100vh;
        width: 0;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1500;
        opacity: 0;
        transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    }

    &__list {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
    }

    &__item {
        margin: 1rem;
    }

    &__link {
        &:link,
        &:visited{
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            color: var(--color-white);
            font-size: 3rem;
            font-weight: 300;
            padding: 1rem 2rem;
            background-image: linear-gradient(120deg, transparent 0%, transparent 50%, var(--color-white) 50% );
            background-size: 230%;
            transition: all .4s;
            
        }
        &:hover,
        &:active {
            background-position: 100%;
            color: var(--color-primary-dark);
            transform: translateX(1rem);
        }
    }

    &__checkbox:checked ~ &__background {
        transform: scale(70);
    }

    &__checkbox:checked ~ &__nav {
        opacity: 1;
        width: 100%;
    }

    &__icon {
        position: relative;
        margin-top: 3.3rem;
        

        &,
        &::before,
        &::after {
            width: 3rem;
            height: 2px;
            // background-color: #000000;
            background-color: var(--color-secondary-dark);
            display: inline-block;
            transition: transform .2;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            transition: transform .3s;
        }

        &::before {top: -.8rem;}
        &::after {top: .8rem;}
    }

    &__button:hover &__icon::before {
        transform: translateY(-.2rem);
    }
    &__button:hover &__icon::after {
        transform: translateY(.2rem);
    }

    &__checkbox:checked + &__button &__icon {
        background-color: transparent;
    }
    &__checkbox:checked + &__button &__icon::before {
        transform: rotate(135deg);
        top: 0;
    }
    &__checkbox:checked + &__button &__icon::after {
        transform: rotate(-135deg);
        top: 0;
    }
}