@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800;8..144,900;8..144,1000&display=swap');

:root {
    
    //Zöld-kék színek
    /*--color-primary-dark: #57cc99;
    --color-primary-dark-rgb: 87, 204, 153;
    --color-primary-med: #CA9C27;
    --color-primary-light: #80ed99;
    --color-primary-light-rgb: 128, 237, 153;

    --color-secondary-dark: #22577a;
    --color-secondary-dark-rgb: 34, 87, 122;
    --color-secondary-light: #38a3a5;
    --color-secondary-light-rgb: 56, 163, 165;
    */

    //Barna-kék színek
    /*--color-primary-dark: #B08968;
    --color-primary-dark-rgb: 176, 137, 104;
    --color-primary-light: #DDB892;
    --color-primary-light-rgb: 221, 184, 146;

    --color-secondary-dark: #4F7596;
    --color-secondary-dark-rgb: 204, 213, 174;
    --color-secondary-light: #688FB0;
    --color-secondary-light-rgb: 233, 237, 201;*/
    
    --color-primary-dark: #CBB886;
    --color-primary-dark-rgb: 203, 184, 134;
    --color-primary-light: #c6b588;
    --color-primary-light-rgb: 198, 181, 136;

    --color-secondary-dark: #4F7596;
    --color-secondary-dark-rgb: 204, 213, 174;
    --color-secondary-light: #688FB0;
    --color-secondary-light-rgb: 233, 237, 201;





    --color-grey-lightest: #ddd;
    --color-grey-light: #eee;
    --color-grey-med: #aaa;
    --color-grey-dark: #777;

    --color-white: #fff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%; //Így 1rem 10px lesz

    @include respond(tab-land) {
        font-size: 56.25%; //1rem = 9px 9/16= 0.5625
    }

    @include respond(tab-port) {
        font-size: 50%; // 1rem = 80px 8/16= 0.5
    }

    @include respond(big-desktop) {
        font-size: 75%; // 1rem = 12px 12/16=0.75
    }

}

body {
    
    font-family: 'Roboto Flex', sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.7;
    padding: 3rem;
}