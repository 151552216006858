@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "base/animations";

@import "base/base";
@import "base/typography";
@import "base/utilities";

@import "components/button";
@import "components/bg-video";
@import "components/logo";
@import "components/project-buttons";
@import "components/project-card";

@import "layout/header";
@import "layout/navigation";
@import "layout/footer";
@import "layout/projects-container";

@import "pages/home";