.bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &__content {
        // height: 100%;
        // width: 100%;
        // object-fit: cover;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%) scale(.5);
        // min-width: 100%;
        // min-height: 100%;
        // opacity: 1;
        // transition: opacity 0.5s ease-out;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(0.75);
        transition: transform 0.5s ease-in-out;


    }
}
.video-title {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // opacity: 0;
    font-size: 5.5rem;
    color: var(--color-primary-dark);
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;

    @include respond(tab-land) {
        font-size: 4.5rem;
        top: 50%;
    }

  }